import React from 'react';

function editscreen() {
    return (
        <div>
            <text>To edit this open Wise Highlights app</text>
        </div>
    );
}

export default editscreen;