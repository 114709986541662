import React, { useState, useEffect } from 'react';
import './App.css'; // Import the CSS file for styling
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Profile from './Profile'; 
import HomeIcon from '@mui/icons-material/Home';  // Import Home icon
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; 

function App() {
  const [language, setLanguage] = useState('en');
  const [activeJobType, setActiveJobType] = useState('Text');
  const [comments, setComments] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [guid, setGuid] = useState('de1313f2-0373-4f31-9ccf-b3e40d15bb40');
  const [init, setInit] = useState([]);
  const [links, setLinks] = useState([]);
  // eslint-disable-next-line
  const [refreshid, setRefreshid] = useState(0);
  const [kind, setKind] = useState('person');
  const [usetext, setusetext] = useState([]);
  // eslint-disable-next-line
  const [buttonstext, setbuttonstext] = useState([]);

  const handleLanguageChange = (event) => setLanguage(event.target.value);
  const handleTabChange = (type) => setActiveJobType(type);
  
  useEffect(() => {
    const timestamp = new Date().getTime();
    fetch(`https://wtimetable.com/QR/GUIDS/${guid}/init.txt?timestamp=${timestamp}`)
      .then((response) => response.text())
      .then((data) => {
        const init = data.split(';');
        setInit(init);
        setKind(init[0]);
        setLanguage(init[1]);
      })
      .catch((error) => console.error(error));

    fetch(`https://wtimetable.com/QR/GUIDS/${guid}/all.txt?timestamp=${timestamp}`)
      .then((response) => response.text())
      .then((data) => {
        const comments = data.split(';');
        setComments(comments);
      })
      .catch((error) => console.error(error));

    fetch(`https://wtimetable.com/QR/GUIDS/${guid}/image.txt?timestamp=${timestamp}`)
      .then((response) => response.text())
      .then((data) => {
        const linesimages = data.split(';');
        setImages(linesimages);
      })
      .catch((error) => console.error(error));

    fetch(`https://wtimetable.com/QR/GUIDS/${guid}/video.txt?timestamp=${timestamp}`)
      .then((response) => response.text())
      .then((data) => {
        const linesvideos = data.split(';');
        setVideos(linesvideos);
      })
      .catch((error) => console.error(error));

      fetch(`https://wtimetable.com/QR/GUIDS/${guid}/links.txt?timestamp=${timestamp}`)
      .then((response) => response.text())
      .then((data) => {
        const linesvideos = data.split(';');
        setLinks(linesvideos);
      })
      .catch((error) => console.error(error));
  }, [guid, refreshid]);

  useEffect(() => {
    const timestamp = new Date().getTime();
    fetch(`https://wtimetable.com/QR/languages/${kind}/${language}.txt?timestamp=${timestamp}`)
      .then((response) => response.text())
      .then((data) => {
        const usetext = data.split(';');
        setusetext(usetext);
      })
      .catch((error) => console.error(error));
    
    fetch(`https://wtimetable.com/QR/languages/zbuttons/${language}.txt?timestamp=${timestamp}`)
      .then((response) => response.text())
      .then((data) => {
        const buttontext = data.split(';');
        setbuttonstext(buttontext);
      })
      .catch((error) => console.error(error));
  }, [language, kind]);
// eslint-disable-next-line
  const handleOpenPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.wiset.fbo', '_blank');
  };

  const handleOpenApp = () => {
    window.open(`wisehighlights://?guid=${guid}`, '_blank');
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('guid')) {
      setGuid(urlParams.get('guid'));
    };
  }, []);

  return (
    <Router basename='qr/application'>
      <div className="App">
        <Routes base>
          <Route path="/" element={
            <div>
              {/* Existing content of your App.js */}
              <div className="headerView">
                <a className='logo' href="https://kenjara.eu/" target="_blank" rel="noopener noreferrer">
                  <img src="https://wtimetable.com/QR/languages/logo.png" className="logoimg" alt="Logo" />
                </a>
                <h1 className="headerText">Wise Highlights</h1>
                <select className="langpicker" value={language} onChange={handleLanguageChange}>
                  <option value="en">English</option>
                  <option value="es">Spanish</option>
                  <option value="fr">French</option>
                  <option value="de">German</option>
                  <option value="it">Italian</option>
                  <option value="slo">Slovenian</option>
                  <option value="hr">Croatian</option>
                </select>
              </div>

              <div className="wcontainer">
                <img src={`https://wtimetable.com/QR/GUIDS/${guid}/profile_picture.png`} className="ppc" alt="Profile" />
                <div className="textcontainer">
                {usetext.map((text, index) => {
              if (text === '') {
                return null;
              }
              if (init[index + 2] === undefined) {
                return null;
              }
              return (
                <p key={index} className='infoText'>{text}{init[index + 2]}</p>
              );
            })}
                </div>
              </div>

              <div className="buttoncontainer">
                <button className={`tab ${activeJobType === 'Text' ? 'active' : ''}`} onClick={() => handleTabChange('Text')}>
                  <text className='buttontext'>{buttonstext[0] ||'Comments'}</text>
                </button>
                <button className={`tab ${activeJobType === 'Image' ? 'active' : ''}`} onClick={() => handleTabChange('Image')}>
                <text className='buttontext'>{buttonstext[1] ||'Images'}</text>
                </button>
                <button className={`tab ${activeJobType === 'Video' ? 'active' : ''}`} onClick={() => handleTabChange('Video')}>
                <text className='buttontext'>{buttonstext[2] || 'Videos'}</text>
                </button>
                <button className={`tab ${activeJobType === 'Link' ? 'active' : ''}`} onClick={() => handleTabChange('Link')}>
                <text className='buttontext'>{buttonstext[14] || 'Links'}</text>
                </button>
              </div>

              <div className="container">
                {activeJobType === 'Text' && (
                  <div className="content">
                    {comments.map((comment, index) => {
                      if (comment === 'demo') {
                        return null;
                      }
                      const [timestamp, text, path] = comment.split('#$&');
                      return (
                        <div key={index} className="commentContainer">
                          <p className='timestamp'>{timestamp}</p>
                          <p className="comment">{path}</p>
                          <p className="comment">{text}</p>
                        </div>
                      );
                    })}
                    {comments.length <= 2 && (
                      <div className="commentContainer">
                        <p className="comment">No comments yet</p>
                      </div>
                    )}
                  </div>
                )}
                {activeJobType === 'Image' && (
                  <div className="content">
                    {images.map((image, index) => {
                      if (image === 'demo') {
                        return null;
                      }
                      const [timestamp, text, path] = image.split('#$&');
                      return (
                        <div key={index} className="commentContainer">
                          <p className='timestamp'>{timestamp}</p>
                          <img src={`https://wtimetable.com/QR/GUIDS/${guid}/images/${path}`} className="image" alt="Content" />
                          <p className="comment">{text}</p>
                        </div>
                      );
                    })}
                    {images.length <= 2 && (
                      <div className="commentContainer">
                        <p className="comment">No images yet</p>
                      </div>
                    )}
                  </div>
                )}
                {activeJobType === 'Video' && (
                  <div className="content">
                    {videos.map((video, index) => {
                      if (video === 'demo') {
                        return null;
                      }
                      const [timestamp, text, path] = video.split('#$&');
                      return (
                        <div key={index} className="commentContainer">
                          <p className='timestamp'>{timestamp}</p>
                          <iframe width="100%" height="315" src={`https://www.youtube.com/embed/${path}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                          <p className="comment">{text}</p>
                        </div>
                      );
                    })}
                    {videos.length <= 2 && (
                      <div className="commentContainer">
                        <p className="comment">No videos yet</p>
                      </div>
                    )}
                  </div>
                )}
                {activeJobType === 'Link' && (
                  <div className="content">
                    {links.map((comment, index) => {
                      if (comment === 'demo') {
                        return null;
                      }
                      const [timestamp, text, path] = comment.split('#$&');
                      return (
                        <div key={index} className="commentContainer">
                          <p className='timestamp'>{timestamp}</p>
                          <p className="comment">{text}</p>
                          <p className="commentApp">to open files use our app</p>
                        </div>
                      );
                    })}
                    {comments.length <= 2 && (
                      <div className="commentContainer">
                        <p className="comment">No links yet</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="footer">
                <button className='downloadApp' onClick={handleOpenPlayStore}>Download our app App</button> <button onClick={handleOpenApp} className='openApp'>Open our App</button>
              </div>
            </div>
          } />
          <Route path="/profile" element={<Profile />} />
        </Routes>

        <div className="bottomNavBar">
          <Link to="/" className="navButton"><HomeIcon/> </Link>
          <Link to="/profile" className="navButton"><AccountCircleIcon /></Link>
        </div>
      </div>
    </Router>
  );
}

export default App;
