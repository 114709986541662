import React from 'react';

function firstinitscreen() {
    return (
        <div>
            <text>To edit this use Wise Highlights app</text>
        </div>
    );
}

export default firstinitscreen;