import React, { useState, useEffect } from 'react';
import './adminview.css'; // Import your CSS file for styling
import moment from 'moment';
import axios from 'axios';
import UserView from './userview';
import UserViewAdmin from './userviewadmin';

const AdminView = ({
    handleLogout,
    getToken,
    userinfo,
    fetchUserQrCodes,
    handleAddQrCode,
    fetchQrLinks,
    qrLinks,
    handleQRBtnPress,
    handleLongPress,
}) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(Math.floor((window.innerHeight - 300) / 65));
  const [inputPage, setInputPage] = useState('');
  const [filteredUserInfo, setFilteredUserInfo] = useState([]);
  const [sortOrderDate, setSortOrderDate] = useState('asc');
  const [sortOrderCodes, setSortOrderCodes] = useState('asc');
  const [sortOrderName, setSortOrderName] = useState('asc');
  const [possibletypes, setPossibletypes] = useState(['']);
  const [userInfo, setUserInfo] = useState([]);
  const [pressedUser, setPressedUser] = useState('');
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    phoneNumber: '',
    type: null,
    phone: '',
    startDate: moment().format('YYYY-DD-MM'), // Set default start date to today
    validUntil: '',
    note: '',
    numberOfQrCodes: '',
  });

  useEffect(() => {
    fetchUsers();
    fetchUserInfo();
    fetchTypes();
    handleRefresh();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterUserInfo();
    // eslint-disable-next-line
  }, [searchTerm, selectedType, users]);

  const fetchUsers = () => {
    fetch('https://wtimetable.com/QR/users.txt?timestamp=' + new Date().getTime())
      .then(response => response.text())
      .then(text => {
        const userArray = text.trim().split('\n');
        setUsers(userArray);
      })
      .catch(error => console.error('Error fetching users:', error));
  };

  const fetchTypes = () => {
    fetch('https://wtimetable.com/QR/languages/types.txt?timestamp=' + new Date().getTime())
        .then(response => response.text())
        .then(text => {
            const typeArray = text.trim().split(';');
            setPossibletypes(typeArray);
        })
        .catch(error => console.error('Error fetching types:', error));
    };

  const fetchUserInfo = () => {
    fetch('https://wtimetable.com/QR/userinfo.txt?timestamp=' + new Date().getTime())
      .then(response => response.text())
      .then(text => {
        const lines = text.trim().split('\n');
        setUserInfo(lines);
      })
      .catch(error => console.error('Error fetching user info:', error));
  };

const filterUserInfo = () => {
    const filtered = userInfo.filter(user => {
        const [name, surname, , , , , , , type] = user.split(";").map(item => item.trim());
        const matchesSearch = `${name} ${surname}`.toLowerCase().includes(searchTerm.toLowerCase());
        //make sure that type matches selected type
        const matchesType = selectedType === '' || type === selectedType;
        return matchesSearch && matchesType;
    });
    setFilteredUserInfo(filtered);
    setCurrentPage(1); // Reset current page to 1 when filtering
};

  const totalPages = Math.ceil(filteredUserInfo.length / usersPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageInputChange = (event) => {
    setInputPage(event.target.value);
  };

  const handlePageJump = () => {
    const pageNumber = parseInt(inputPage, 10);
    handlePageChange(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const sortByDate = () => {
    const sorted = [...filteredUserInfo].sort((a, b) => {
      const [, , , , , , dateA] = a.split(";").map(item => item.trim());
      const [, , , , , , dateB] = b.split(";").map(item => item.trim());
      return sortOrderDate === 'asc'
        ? new Date(dateA) - new Date(dateB)
        : new Date(dateB) - new Date(dateA);
    });
    setFilteredUserInfo(sorted);
    setSortOrderDate(sortOrderDate === 'asc' ? 'desc' : 'asc');
  };

  const sortByName = () => {
    const sorted = [...filteredUserInfo].sort((a, b) => {
      const [nameA, surnameA] = a.split(";").map(item => item.trim()).slice(0, 2);
      const [nameB, surnameB] = b.split(";").map(item => item.trim()).slice(0, 2);
      return sortOrderName === 'asc' ?
        nameA.localeCompare(nameB) || surnameA.localeCompare(surnameB) :
        nameB.localeCompare(nameA) || surnameB.localeCompare(surnameA);
    });
    setFilteredUserInfo(sorted);
    setSortOrderName(sortOrderName === 'asc' ? 'desc' : 'asc');
  };

  const sortByNumber = () => {
    const sorted = [...filteredUserInfo].sort((a, b) => {
      const [, , , , numA] = a.split(";").map(item => item.trim());
      const [, , , , numB] = b.split(";").map(item => item.trim());
      return sortOrderCodes === 'asc'
        ? parseInt(numA) - parseInt(numB)
        : parseInt(numB) - parseInt(numA);
    });
    setFilteredUserInfo(sorted);
    setSortOrderCodes(sortOrderCodes === 'asc' ? 'desc' : 'asc');
  };

  const getPaginatedUsers = () => {
    const startIndex = (currentPage - 1) * usersPerPage;
    const endIndex = startIndex + usersPerPage;
    return filteredUserInfo.slice(startIndex, endIndex);
  };

  const handleRefresh = () => {
    fetchUsers();
    fetchUserInfo();
  };

  const handleAddUser = () => {
    // Implement your add user logic
    setAddUserModalOpen(true);
  };

  const handleEdit = (email) => {
    // Implement your edit logic
    setPressedUser(email);
    setEditUserModalOpen(true);
  };

  const updateUserinfoFile = async (name, surname, email, phoneNumber, numberOfQrCodes, startDate, validUntil, note, type) => {
    const newUser = `${name};${surname};${email};${phoneNumber};${numberOfQrCodes};${startDate};${validUntil};${note};${type}\n`;
  
    try {
        const response = await fetch('https://wtimetable.com/QR/userinfo.txt?timestamp=' + new Date().getTime());
        const text = await response.text();
        const updatedText = text + newUser;
        const documentContent = btoa(updatedText);
        const userToken = await getToken();
        const BearerToken = 'Bearer ' + userToken;
  
        const data = JSON.stringify({
            documentPath: 'userinfo.txt',
            documentContent: documentContent
        });
  
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': BearerToken
            },
            data: data
        };
  
        const uploadResponse = await axios.request(config);
        console.log(JSON.stringify(uploadResponse.data));
  
    } catch (error) {
        console.error(error);
    }
  };

  const updateUserFile = async (email, password) => {
    const newUser = `${email}-,-,-,${password}\n`;
  
    try {
        const response = await fetch('https://wtimetable.com/QR/users.txt?timestamp=' + new Date().getTime());
        const text = await response.text();
        const updatedText = text + newUser;
        const documentContent = btoa(updatedText);
        const userToken = await getToken();
        const BearerToken = 'Bearer ' + userToken;
  
        const data = JSON.stringify({
            documentPath: 'users.txt',
            documentContent: documentContent
        });
  
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': BearerToken
            },
            data: data
        };
  
        const uploadResponse = await axios.request(config);
        console.log(JSON.stringify(uploadResponse.data));
  
    } catch (error) {
        console.error(error);
    }
  };

  const createUserQrFolder = async (email, numberOfQrCodes, type) => {
    const newUser = `${email};${numberOfQrCodes};${type};0\n`;

    try {
        const updatedText = `${newUser}`;
        const documentContent = btoa(updatedText);
        const userToken = await getToken();
        const BearerToken = 'Bearer ' + userToken;

        const data = JSON.stringify({
            documentPath: 'USERS/' + email + '/QrFile.txt',
            documentContent: documentContent
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': BearerToken
            },
            data: data
        };

        const uploadResponse = await axios.request(config);
        console.log(JSON.stringify(uploadResponse.data));

    } catch (error) {
        console.error(error);
    }
    // create QrLinks folder

  try {
    const documentContent = btoa('init');
    const userToken = await getToken();
    const BearerToken = 'Bearer ' + userToken;

    const data = JSON.stringify({
      documentPath: 'USERS/' + email + '/QrLinks.txt',
      documentContent: documentContent
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': BearerToken
      },
      data: data
    };

    const uploadResponse = await axios.request(config);
    console.log(JSON.stringify(uploadResponse.data));

  } catch (error) {
    console.error(error);
    alert('Failed to create QR Links File');
  }
};

  const handleAddUserSubmit = () => {
    const { name, surname, password, email, phoneNumber, type, startDate, validUntil, note, numberOfQrCodes } = newUser;
    if (name && surname && password && email && type && startDate && validUntil && numberOfQrCodes) {

      // Add user to the file
      updateUserinfoFile(newUser.name, newUser.surname, newUser.email, newUser.phoneNumber, newUser.numberOfQrCodes, newUser.startDate, newUser.validUntil, newUser.note, newUser.type);
      updateUserFile(newUser.email, newUser.password);
      createUserQrFolder(newUser.email, newUser.numberOfQrCodes, newUser.type);



      console.log(`Adding user: ${name} ${surname}, Email: ${email}, Phone Number: ${phoneNumber}, Type: ${type}, Start Date: ${startDate}, Valid Until: ${validUntil}, Note: ${note}, QR Codes: ${numberOfQrCodes}`);
      // Reset form
      setNewUser({
        name: '',
        surname: '',
        email: '',
        password: '',
        phoneNumber: '',
        type: null,
        phone: '',
        startDate: moment().format('YYYY-DD-MM'),
        validUntil: '',
        note: '',
        numberOfQrCodes: '',
      });
      setAddUserModalOpen(false);
    } else {
      alert('Please fill all the fields');
    }
  };

  return (
    <div className="admin-view">
    <div className="headerView">
    <a className='logo' href="https://kenjara.eu/" target="_blank" rel="noopener noreferrer">
        <img src="https://wtimetable.com/QR/languages/logo.png" className="logoimg" alt="Logo" />
    </a>
    <h1 className="headerText">Wise Highlights</h1>
    <button onClick={handleRefresh} className="refresh-button">Refresh</button>
    </div>
    <div className="search-container">
      <input
          type="text"
          className="search-input"
          placeholder="Search by name or surname"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          className="picker"
        >
            <option value="">All types</option>
        {possibletypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
        ))}
        </select>
      </div>
      <div className="table-header">
        <button onClick={sortByName} className="admin-header-text">
          Name & Surname ({sortOrderName === 'asc' ? '▼' : '▲'})
        </button>
        <button onClick={sortByDate} className="admin-header-text">
          Valid until ({sortOrderDate === 'asc' ? '▼' : '▲'})
        </button>
        <button onClick={sortByNumber} className="admin-header-text">
          Number of codes ({sortOrderCodes === 'asc' ? '▼' : '▲'})
        </button>
      </div>
      {getPaginatedUsers().map((user, index) => {
        const [name, surname, email, , numberOfCodes, , validUntil] = user.split(";").map(item => item.trim());
        return (
          <div key={index} className="row">
            <div className="cell">
              <span className="text">{`${name} ${surname}`}</span>
            </div>
            <div className="cell">
              <span className="text">{validUntil}</span>
            </div>
            <div className="cell">
              <span className="text">{numberOfCodes}</span>
            </div>
            <button className="edit-button" onClick={() => handleEdit(email)}>
              Edit
            </button>
          </div>
        );
      })}
      <div className="pagination">
        <button
          className="page-button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>
        <input
          type="text"
          className="page-input"
          value={inputPage}
          onChange={handlePageInputChange}
          onBlur={handlePageJump}
          placeholder={`Page ${currentPage}`}
        />
        <button
          className="page-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>
      </div>
      <button className="button" onClick={handleAddUser}>
        ADD USER
      </button>
      <button className="button" onClick={handleLogout}>
        LOGOUT
      </button>
      <div className='whitespace'></div>
      {/* here the adduser modal should be */}
      {addUserModalOpen &&
      <div className={`modal-${addUserModalOpen ? 'open' : ''}`}>
        <div className="modal-container">
        <text className="modal-title">Add New User</text>
        <input type="text" class="modal-input" placeholder="Name" value={newUser.name} onChange={(e) => setNewUser({ ...newUser, name: e.target.value })} />
        <input type="text" class="modal-input" placeholder="Surname" value={newUser.surname} onChange={(e) => setNewUser({ ...newUser, surname: e.target.value })} />
        <input type="email" class="modal-input" placeholder="Email" value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
        <input type="text" class="modal-input" placeholder="Password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
        <input type="text" class="modal-input" placeholder="Phone Number (optional)" value={newUser.phoneNumber} onChange={(e) => setNewUser({ ...newUser, phoneNumber: e.target.value })} />

        <select class="picker-add-user" value={newUser.type} onChange={(e) => setNewUser({ ...newUser, type: e.target.value })}>
          <option value="">Select Type</option>
          {possibletypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>
        
        <input type="date" class="modal-input" placeholder="Start Date" value={newUser.startDate} onChange={(e) => setNewUser({ ...newUser, startDate: e.target.value })} />
        <input type="date" class="modal-input" placeholder="Valid Until" value={newUser.validUntil} onChange={(e) => setNewUser({ ...newUser, validUntil: e.target.value })} />
        <input type="text" class="modal-input" placeholder="Note (optional)" value={newUser.note} onChange={(e) => setNewUser({ ...newUser, note: e.target.value })} />
        <input type="number" class="modal-input" placeholder="Number of QR Codes" value={newUser.numberOfQrCodes} onChange={(e) => setNewUser({ ...newUser, numberOfQrCodes: e.target.value })} />
        
        <button class="modal-button" onClick={handleAddUserSubmit}>
          Submit
        </button>
        <button class="modal-button" onClick={() => setAddUserModalOpen(false)}>Close</button>
        </div>
      </div>
      }
      {editUserModalOpen && <div className={`modal-${editUserModalOpen ? 'open' : ''}`}>
        
        {/* <UserView 
        userloggedin={pressedUser}
        admin = {true}
        userinfo={userinfo}
        fetchUserQrCodes={fetchUserQrCodes}
        addQrCode={handleAddQrCode}
        fetchQrLinks={fetchQrLinks}
        qrLinks={qrLinks}
        showQRCodeLong={editUserModalOpen}
        setShowQRCodeLong={() => setEditUserModalOpen(false)}
        handleQRBtnPress={handleQRBtnPress}
        handleLongPress={handleLongPress}
        /> */}
            <UserViewAdmin
            fetchUserQrCodes={fetchUserQrCodes}
            fetchQrLinks={fetchQrLinks}
            qrLinks={qrLinks}
            userinfo={userinfo}
            handleQRBtnPress={handleQRBtnPress}
            handleLongPress={handleLongPress}
            userloggedin={pressedUser}
            admin={true}
            />
          <button className='buttonCloseAtBottom' onClick={() => setEditUserModalOpen(false)}>Close</button>
        </div>
      }
    </div>
  );
};

export default AdminView;
