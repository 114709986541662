import React from 'react';
import Modal from 'react-modal';
import './editqrcodemodal.css'; // Your CSS file for styling
import FirstInitScreen from './assets/firstinitscreen';
import EditScreen from './assets/editscreen';

Modal.setAppElement('#root'); // Set the root element for accessibility

function EditQRCodeModal({
  showEditQRModal,
  setShowEditQRModal,
  initDemoValueTest,
  userloggedin,
  useradminloggedin,
  qrGuid,
}) {
  return (
    <Modal
      isOpen={showEditQRModal}
      onRequestClose={() => setShowEditQRModal(false)}
      className="modalContainer"
      overlayClassName="modalOverlay"
      contentLabel="Edit QR Code Modal"
    >
      <div className="modalContent">
        {initDemoValueTest === 'person;en;SetUpYourQRCode;Demo;Demo;Demo;Demo;Demo' && userloggedin !== 'admin' ? (
          <FirstInitScreen init={initDemoValueTest} userloggedin={userloggedin} guid={qrGuid} />
        ) : (
          <EditScreen
            init2={initDemoValueTest}
            guid={qrGuid}
            userloggedin={userloggedin === 'admin' ? useradminloggedin : userloggedin}
          />
        )}
        <button className="closeButton" onClick={() => setShowEditQRModal(false)}>
          Close
        </button>
      </div>
    </Modal>
  );
}

export default EditQRCodeModal;
