import React, { useState, useEffect } from 'react';
import {QRCodeCanvas} from 'qrcode.react';
import Modal from 'react-modal';

function UserView({
    admin,
    userloggedin,
    handleLogout,
    userinfo,
    fetchUserQrCodes,
    addQrCode,
    fetchQrLinks,
    qrLinks,
    handleQRBtnPress,
    handleLongPress,
    showQRCodeLong,
    setShowQRCodeLong,
  }) {
    const [numberOfQRCodes, setNumberOfQRCodes] = useState(0);
    const [numberOfQRCodesGenerated, setNumberOfQRCodesGenerated] = useState(0);
    const [userDetails, setUserDetails] = useState(null);
  
    useEffect(() => {
      fetchUserQrCodes(userloggedin).then((data) => {
        const numberOfQRCodes = data ? parseInt(data.split(";")[1], 10) : 0;
        const numberOfQRCodesGenerated = data ? parseInt(data.split(";")[3], 10) : 0;
        setNumberOfQRCodes(numberOfQRCodes);
        setNumberOfQRCodesGenerated(numberOfQRCodesGenerated);
      });
      fetchQrLinks(userloggedin);
    }, [userloggedin, fetchUserQrCodes, addQrCode, fetchQrLinks]);
  
    useEffect(() => {
      if (userinfo) {
        const user = userinfo.find((info) => {
          const [, , email] = info.split(";").map(item => item.trim());
          return email === userloggedin;
        });
        setUserDetails(user);
      }
    }, [userinfo, userloggedin]);
  
    return (
    //   <Modal
    //   isOpen={showQRCodeLong}
    //   onRequestClose={() => setShowQRCodeLong(false)}
    //   className="modalContainerQRCloseup"
    //   overlayClassName="modalOverlay"
    //   contentLabel="QR Code Modal"
    // >
      <div style={{ flex: 1, backgroundColor: 'white', width: '100%' }}>
        <div className="headerView">
                <a className='logo' href="https://kenjara.eu/" target="_blank" rel="noopener noreferrer">
                  <img src="https://wtimetable.com/QR/languages/logo.png" className="logoimg" alt="Logo" />
                </a>
                <h1 className="headerText">Wise Highlights</h1>
  
          {admin ? (
            <button style={styles.userlogoutbutton} onClick={handleLogout}>
              ADMIN DELETE USER
            </button>
          ) : (
            <button style={styles.userlogoutbutton} onClick={handleLogout}>
              LOGOUT
            </button>
          )}
        </div>
        <div style={styles.userheader}>
          <div style={styles.yourcodesheader}>
            <h2 style={styles.userWelcomeText}>Welcome {userloggedin}</h2>
          </div>
          {userDetails && (
            <div style={styles.userInfoContainer}>
              <p style={styles.userInfoText}>Name: {userDetails.split(";")[0]}</p>
              <p style={styles.userInfoText}>Surname: {userDetails.split(";")[1]}</p>
              <p style={styles.userInfoText}>Number of QR Codes: {numberOfQRCodes}</p>
              <p style={styles.userInfoText}>Number of QR Codes Generated: {numberOfQRCodesGenerated}</p>
              <p style={styles.userInfoText}>Start Date: {userDetails.split(";")[5]}</p>
              <p style={styles.userInfoText}>Valid Until: {userDetails.split(";")[6]}</p>
              <p style={styles.userInfoText}>Note: {userDetails.split(";")[7]}</p>
            </div>
          )}
        </div>
  
        <div style={styles.yourcodesheader}>
          <h2 style={styles.userWelcomeText}>Your QR Codes (long press to open)</h2>
        </div>
  
        <div style={styles.qrButtonsContainer}>
          {Array.from({ length: numberOfQRCodesGenerated }).map((_, index) => (
            <button
              key={index}
              style={styles.qrButton}
              onContextMenu={(e) => {
                e.preventDefault();
                handleLongPress(qrLinks[index]);
              }}
              onClick={() => handleQRBtnPress(qrLinks[index])}
            >
               <QRCodeCanvas value={`https://wtimetable.com/QR/Application/?guid=${qrLinks[index]}`} />
              <p style={styles.qrButtonText}>QR Code {qrLinks[index] ? qrLinks[index].substring(0, 16) : '/'}</p>
            </button>
          ))}
  
          {!admin && numberOfQRCodesGenerated < numberOfQRCodes && (
            <button style={styles.qrAddButton} onClick={addQrCode}>
              <text style={styles.plus}>+</text>
            </button>
          )}
  
          {admin && numberOfQRCodesGenerated === 0 && (
            <p>User has not generated any codes yet.</p>
          )}
  
          {(numberOfQRCodesGenerated % 2 === 0 && numberOfQRCodesGenerated !== 0) && <div style={styles.plain} />}
          {(numberOfQRCodes % 2 !== 0 && numberOfQRCodesGenerated !== 0) && <div style={styles.plain} />}
        </div>
      </div>
      // </Modal>
    );
  }


  const styles = {
    container: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    headerView: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        width: 50,
        height: 50,
        position: 'absolute',
        left: 10,
        top: 10,
    },
    adminHeaderText: {
        marginLeft: 10,
    },
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        padding: 20,
        border: '1px solid #ccc',
        borderRadius: 5,
        margin: '0 auto',
        marginTop: window.innerHeight / 5,

    },
    input: {
        padding: 10,
        marginBottom: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
    },
    button: {
        padding: 10,
        borderRadius: 5,
        border: 'none',
        backgroundColor: '#1C2541',
        color: '#fff',
        cursor: 'pointer',
    },
      userlogoutbutton: {
        textAlign: 'center',
        padding: 10,
        borderRadius: 5,
        position: 'absolute',
        right: 10,
        top: 10,
        border: 'none',
      },
      userheader: {
        width: '100%',
        backgroundColor: '#1C2541',
        justifyContent: 'center',
        top: 0,
      },
      yourcodesheader: {
        paddingTop: 1,
        width: '100%',
        backgroundColor: '#1C2541',
        justifyContent: 'center',
        top: 0,
        height: 50,
      },
      userWelcomeText: {
        color: 'white',
        textAlign: 'center',
        fontSize: 20,
      },
      userInfoContainer: {
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,

      },
      userInfoText: {
        color: '#1C2541',
        fontSize: 16,
        marginBottom: 5,
        //a black bottom border
        borderBottom: '1px solid #1C2541',
      },
      qrButtonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        padding: '10px',
      },
      qrButton: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer',
        borderColor: '#1C2541',
        width: '180px',
      },
      qrButtonText: {
        marginTop: '10px',
        fontSize: '14px',
      },
      qrAddButton: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '200px',
        cursor: 'pointer',
        borderColor: '#1C2541',
        borderWidth: '5px',
        backgroundColor: 'white',
        color: 'white',
      },
      plain: {
        flexBasis: '100%',
        height: '1px',
        backgroundColor: '#f0f0f0',
        margin: '20px 0',
      },
      plus: {
        fontSize: '120px',
        color: '#1C2541',
      },
};

export default UserView;