        
import React, { useState, useEffect } from 'react';
import {QRCodeCanvas} from 'qrcode.react';
import Modal from 'react-modal';
import { FaPen } from 'react-icons/fa';
import moment from 'moment';
import axios from 'axios';

export default function UserViewAdmin({
    admin,
    userloggedin,
    handleLogout,
    userinfo,
    fetchUserQrCodes,
    addQrCode,
    fetchQrLinks,
    qrLinks,
    handleQRBtnPress,
    handleLongPress,
    showQRCodeLong,
    setShowQRCodeLong,

    }) {
    const [numberOfQRCodes, setNumberOfQRCodes] = useState(0);
    const [numberOfQRCodesGenerated, setNumberOfQRCodesGenerated] = useState(0);
    const [userDetails, setUserDetails] = useState(null);
    const [editUserModalOpen, setEditUserModalOpen] = useState(false);
    const screenWidth = window.innerWidth;  
    const [possibletypes, setPossibleTypes] = useState(['Admin', 'User']);
    const [qrData, setQrData] = useState([]);
    const [editUser, setEditUser] = useState({
      name: '',
      surname: '',
      email: '',
      password: '',
      phoneNumber: '',
      type: null,
      phone: '',
      startDate: moment().format('YYYY-DD-MM'), // Set default start date to today
      validUntil: '',
      note: '',
      numberOfQrCodes: '',
    });   

    const getToken = async () => {
      const auth = 'Basic d3R0X2FwaV91c2VyOlQwUlRQTnFrYmt3M2VhcWQ=';
  
      try {
          const response = await fetch('https://www.wise-tt.com/WTTWebRestAPI/ws/rest/login', {
              method: 'GET',
              headers: {
                  'Authorization': auth,
              },
          });
  
          if (response.ok) {
              const data = await response.json();
              return data.token;
          } else {
              alert('Login failed');
          }
      } catch (error) {
          alert('An error occurred');
          alert(error);
      }
  };

  const fetchpossibletypes = () => {
    //a function that fetches from the server qr/languages/types.txt and returns the types
    fetch('https://wtimetable.com/QR/languages/types.txt?timestamp=' + new Date().getTime())
      .then(response => response.text())
      .then(text => {
        const lines = text.trim().split(';');
        setPossibleTypes(lines);
      })
      .catch(error => {
        console.error('Error fetching types:', error);
      });
  };

  useEffect(() => {
    fetchpossibletypes();
    fetchUserQrCodes(userloggedin).then((data) => {
        const numberOfQRCodes = data ? parseInt(data.split(";")[1], 10) : 0;
        const numberOfQRCodesGenerated = data ? parseInt(data.split(";")[3], 10) : 0;
        setNumberOfQRCodes(numberOfQRCodes);
        setNumberOfQRCodesGenerated(numberOfQRCodesGenerated);
    });
    fetchQrLinks(userloggedin);
  }, [userloggedin, fetchUserQrCodes, addQrCode, fetchQrLinks]);

 useEffect(() => {
      if (userinfo) {
        const user = userinfo.find((info) => {
            const [, , email] = info.split(";").map(item => item.trim());
            return email === userloggedin;
          });
        setUserDetails(user);
    }
  }
  , [userinfo, userloggedin]);
  const getUserInfo = (user) => {
    fetch('https://wtimetable.com/QR/userinfo.txt?timestamp=' + new Date().getTime())
      .then(response => response.text())
      .then(text => {
        const lines = text.trim().split('\n');
        const userInfo = lines.find(line => line.includes(user));
        if (userInfo) {
          const [name, surname, email, phoneNumber, numberOfQrCodes, startDate, validUntil, note, type] = userInfo.split(";").map(item => item.trim());
          setEditUser({
            name,
            surname,
            email,
            phoneNumber,
            numberOfQrCodes,
            startDate,
            validUntil,
            note,
            type
          });
        }
      })
      .catch(error => {
        console.error('Error fetching user info:', error);
      });
  };
    const updateUserinfoFileAdmin = async (name, surname, email, phoneNumber, numberOfQrCodes, startDate, validUntil, note, type) => {
      const editUser = `${name};${surname};${email};${phoneNumber};${numberOfQrCodes};${startDate};${validUntil};${note};${type}`;
    
      try {
          const response = await fetch('https://wtimetable.com/QR/userinfo.txt?timestamp=' + new Date().getTime());
          const text = await response.text();
          const lines = text.trim().split('\n');
          const updatedText = lines.map(line => {
            if (line.includes(email)) {
              return editUser;
            }
            return line;
          }).join('\n');
        
          const documentContent = btoa(updatedText);
          const userToken = await getToken();
          const BearerToken = 'Bearer ' + userToken;
    
          const data = JSON.stringify({
              documentPath: 'userinfo.txt',
              documentContent: documentContent
          });
    
          const config = {
              method: 'post',
              maxBodyLength: Infinity,
              url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': BearerToken
              },
              data: data
          };
    
          const uploadResponse = await axios.request(config);
          console.log(JSON.stringify(uploadResponse.data));
    
      } catch (error) {
          console.error(error);
      }
    };

    const updateUserNumberOfQrCodes = async (email, numberOfQrCodes) => {
      const data = await fetchUserQrCodes(email);
      const [fetchedEmail, , type, numberOfQrCodesGenerated] = data.split(";").map(item => item.trim());
      const newUser = `${fetchedEmail};${numberOfQrCodes};${type};${numberOfQrCodesGenerated}\n`;
  
      const updatedText = newUser;
      const documentContent =btoa(updatedText);
      const userToken = await getToken();
      const BearerToken = 'Bearer ' + userToken;
  
        const Requestdata = JSON.stringify({
          documentPath: 'USERS/' + email + '/QrFile.txt',
          documentContent: documentContent
        });
  
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': BearerToken
          },
          data: Requestdata
        };
  
        axios.request(config)
        .then(response => {
          console.log(JSON.stringify(response.data));
        }
        )
        .catch(error => {
          console.error(error);
          alert('Failed to update User QR Folder');
        });
    };

    const handleEditUserSubmit = () => {
      const { name, surname, password, email, phoneNumber, type, startDate, validUntil, note, numberOfQrCodes } = editUser;
      if (name && surname && email && type && startDate && validUntil && numberOfQrCodes) {
  
        // Add user to the file
        updateUserinfoFileAdmin(editUser.name, editUser.surname, editUser.email, editUser.phoneNumber, editUser.numberOfQrCodes, editUser.startDate, editUser.validUntil, editUser.note, editUser.type);
        updateUserNumberOfQrCodes(editUser.email, editUser.numberOfQrCodes);
  
  
        console.log(`Adding user: ${name} ${surname}, Email: ${email}, Phone Number: ${phoneNumber}, Type: ${type}, Start Date: ${startDate}, Valid Until: ${validUntil}, Note: ${note}, QR Codes: ${numberOfQrCodes}`);
        // Reset form
        setEditUser({
          name: '',
          surname: '',
          email: '',
          password: '',
          phoneNumber: '',
          type: null,
          phone: '',
          startDate: moment().format('YYYY-DD-MM'),
          validUntil: '',
          note: '',
          numberOfQrCodes: '',
        });
        setEditUserModalOpen(false);
      } else {
        alert('Please fill all the fields');
      }
    };

    const editUserInfo = () => {
      setEditUserModalOpen(true);
      getUserInfo(userloggedin);
    }

    useEffect(() => {
      const fetchData = async () => {
        const dataPromises = qrLinks.map(link =>
          fetch(`https://wtimetable.com/QR/GUIDS/${link}/init.txt`)
            .then(response => response.text())
            .then(data => {
              const parts = data.split(';');
              return `${parts[2] || 'N/A'} ${parts[3] || 'N/A'}`;
            })
            .catch(() => 'Error fetching data')
        );
        const results = await Promise.all(dataPromises);
        setQrData(results);
      } 
      fetchData();
    }, [qrLinks]);

    const deleteUser = () => {
      alert('Function under construction');
    }

    return (
      <div style={{ flex: 1, backgroundColor: 'white', width: '100%' }}>
        <div className="headerView">
          <a className='logo' href="https://kenjara.eu/" target="_blank" rel="noopener noreferrer">
            <img src="https://wtimetable.com/QR/languages/logo.png" className="logoimg" alt="Logo" />
          </a>
          <h1 className="headerText">Wise Highlights</h1>
        
          {admin ? (
            <button style={styles.userlogoutbutton} onClick={deleteUser}>
              {screenWidth < 420 ? 'Delete' : 'ADMIN DELETE USER'}
            </button>
          ) : (
            <button style={styles.userlogoutbutton} onClick={handleLogout}>
        LOGOUT
            </button>
          )}
        </div>
        <div style={styles.userheader}>
          <div style={styles.yourcodesheader}>
            <h2 style={styles.userWelcomeText}>Welcome {userloggedin}</h2>
            {admin && (
            <button style={styles.adminButton} onClick={editUserInfo}>
        <FaPen />
            </button>
            )}
          </div>
          {userDetails && (
            <div style={styles.userInfoContainer}>
        <p style={styles.userInfoText}>Name: {userDetails.split(";")[0]}</p>
        <p style={styles.userInfoText}>Surname: {userDetails.split(";")[1]}</p>
        <p style={styles.userInfoText}>Number of QR Codes: {numberOfQRCodes}</p>
        <p style={styles.userInfoText}>Number of QR Codes Generated: {numberOfQRCodesGenerated}</p>
        <p style={styles.userInfoText}>Start Date: {userDetails.split(";")[5]}</p>
        <p style={styles.userInfoText}>Valid Until: {userDetails.split(";")[6]}</p>
        <p style={styles.userInfoText}>Note: {userDetails.split(";")[7]}</p>
            </div>
          )}
        </div>
        
        <div style={styles.yourcodesheader}>
          <h2 style={styles.userWelcomeText}>Your QR Codes (long press to open)</h2>
        </div>
        
        <div style={styles.qrButtonsContainer}>
          {Array.from({ length: numberOfQRCodesGenerated }).map((_, index) => (
            <button
        key={index}
        style={styles.qrButton}
        onContextMenu={(e) => {
          e.preventDefault();
          handleLongPress(qrLinks[index]);
        }}
        onClick={() => handleQRBtnPress(qrLinks[index])}
            >
         <QRCodeCanvas value={`https://wtimetable.com/QR/Application/?guid=${qrLinks[index]}`} />
        <p style={styles.qrButtonText}>
          {qrData[index]}
        </p>
            </button>
          ))}

          {!admin && numberOfQRCodesGenerated < numberOfQRCodes && (
            <button style={styles.qrAddButton} onClick={addQrCode}>
        <text style={styles.plus}>+</text>
            </button>
          )}

          {admin && numberOfQRCodesGenerated === 0 && (
            <p>User has not generated any codes yet.</p>
          )}

          {(numberOfQRCodesGenerated % 2 === 0 && numberOfQRCodesGenerated !== 0) && <div style={styles.plain} />}
          <div style={styles.plain} />
          {(numberOfQRCodes % 2 !== 0 && numberOfQRCodesGenerated !== 0) && <div style={styles.plain} />}
        </div>
        {editUserModalOpen && (
          <div style={styles.modalopen}>
            <div style={styles.loginContainer}>
              <input style={styles.input} placeholder="Name" value={editUser.name} onChange={(e) => setEditUser({ ...editUser, name: e.target.value })}/>
              <input style={styles.input} placeholder="Surname" value={editUser.surname} onChange={(e) => setEditUser({ ...editUser, surname: e.target.value })} />
              <input style={styles.input} placeholder="Email" value={editUser.email} onChange={(e) => setEditUser({ ...editUser, email: e.target.value })} />
              <input style={styles.input} placeholder="PhoneNumber" value={editUser.phoneNumber} onChange={(e) => setEditUser({ ...editUser, phoneNumber: e.target.value })} />
              <select style={styles.input} value={editUser.type} onChange={(e) => setEditUser({ ...editUser, type: e.target.value })}>
                <option value="">Select Type</option>
                {possibletypes.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
              <input style={styles.input} placeholder="StartDate" value={editUser.startDate} onChange={(e) => setEditUser({ ...editUser, startDate: e.target.value })} />
              <input style={styles.input} placeholder="validUntil" value={editUser.validUntil} onChange={(e) => setEditUser({ ...editUser, validUntil: e.target.value })} />
              <input style={styles.input} placeholder="Note" value={editUser.note} onChange={(e) => setEditUser({ ...editUser, note: e.target.value })} />
              <input style={styles.input} placeholder="Number of qr codes" value={editUser.numberOfQrCodes} onChange={(e) => setEditUser({ ...editUser, numberOfQrCodes: e.target.value })} />
              <button style={styles.button} onClick={handleEditUserSubmit}>Update</button>
            </div>
            <button style={styles.userlogoutbutton} onClick={() => setEditUserModalOpen(false)}>Close</button>
          </div>
        )}
      </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        overflow: 'auto',
    },
    headerView: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        width: 50,
        height: 50,
    },
    adminHeaderText: {
        marginLeft: 10,
    },
    adminButton: {
      position: 'absolute',
      right: 15,
      top: 73,
      backgroundColor: 'white',
      border: 'none',
      cursor: 'pointer',
      borderRadius: 5,
      padding: 5,
    },
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        padding: 20,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: 5,
        margin: '0 auto',
        marginTop: window.innerHeight * 0.1,

    },
    modalopen: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: window.innerHeight,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 100,
    },

    input: {
        padding: 10,
        marginBottom: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
    },
    button: {
        padding: 10,
        borderRadius: 5,
        border: 'none',
        backgroundColor: '#1C2541',
        color: '#fff',
        cursor: 'pointer',
    },
      userlogoutbutton: {
        textAlign: 'center',
        padding: 10,
        borderRadius: 5,
        position: 'absolute',
        right: 10,
        top: 10,
        border: 'none',
        
      },
      userheader: {
        width: '100%',
        backgroundColor: '#1C2541',
        justifyContent: 'center',
        top: 0,
      },
      yourcodesheader: {
        paddingTop: 1,
        width: '100%',
        backgroundColor: '#1C2541',
        justifyContent: 'center',
        top: 0,
        height: 70,
      },
      userWelcomeText: {
        color: 'white',
        textAlign: 'center',
        fontSize: 20,
      },
      userInfoContainer: {
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,

      },
      userInfoText: {
        color: '#1C2541',
        fontSize: 16,
        marginBottom: 5,
        //a black bottom border
        borderBottom: '1px solid #1C2541',
      },
      qrButtonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        padding: '10px',
        justifyContent: 'center',
      },
      qrButton: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '48%',
        maxWidth: '200px',
      },
      qrButtonText: {
        marginTop: '10px',
        fontSize: '14px',
      },
      qrAddButton: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '48%',
        maxWidth: '200px',
        cursor: 'pointer',
        borderColor: '#1C2541',
        borderWidth: '5px',
        backgroundColor: 'white',
        color: 'white',
      },
      plain: {
        flexBasis: '100%',
        height: '1px',
        backgroundColor: '#f0f0f0',
        margin: '20px 0',
      },
      plus: {
        fontSize: '120px',
        color: '#1C2541',
      },
};