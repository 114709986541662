import React, { useRef } from 'react';
import Modal from 'react-modal';
import {QRCodeCanvas} from 'qrcode.react';
import './qrcodemodal.css'; // Your CSS file for styling

Modal.setAppElement('#root'); // Make sure to set the root element for accessibility

function QRCodeModal({ showQRCodeLong, setShowQRCodeLong, qrGuid, downloadQRCode, handleOpenQrCode }) {
  const qrCodeRef = useRef();

  return (
    <Modal
      isOpen={showQRCodeLong}
      onRequestClose={() => setShowQRCodeLong(false)}
      className="modalContainerQRCloseup"
      overlayClassName="modalOverlay"
      contentLabel="QR Code Modal"
    >
      <div className="qrCodeCloseUp">
        <QRCodeCanvas
          value={`https://wtimetable.com/QR/Application/?guid=${qrGuid}`}
          size={window.innerHeight * 0.35}
          ref={qrCodeRef}
        />
        <button className="closeQrButton" onClick={() => downloadQRCode(qrGuid)}>
          Save to images
        </button>
        <button className="closeQrButton" onClick={() => handleOpenQrCode(qrGuid)}>
          Open
        </button>
        <button className="closeQrButton" onClick={() => setShowQRCodeLong(false)}>
          Close
        </button>
      </div>
    </Modal>
  );
}

export default QRCodeModal;
