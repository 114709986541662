import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {QRCodeCanvas} from 'qrcode.react';
import QRCodeModal from './qrcodemodal';
import EditQRCodeModal from './editqrcodemodal';
import AdminView from './assets/adminview';
import { max } from 'moment';

function App() {
    const [userLoggedIn, setUserLoggedIn] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [users, setUsers] = useState({});
    // eslint-disable-next-line
    const [userinfo, setUserinfo] = useState([]);
        // eslint-disable-next-line
    const [filteredUserInfo, setFilteredUserInfo] = useState([]);
        // eslint-disable-next-line
    const [usersPerPage, setUsersPerPage] = useState(0);
    const [qrLinks, setQrLinks] = useState([]);
    // eslint-disable-next-line
    const [guid, setGuid] = useState('');
    // eslint-disable-next-line
    const [showEditQRModal, setShowEditQRModal] = useState(false);
    // eslint-disable-next-line
    const [showQRCodeLong, setShowQRCodeLong] = useState(false);
    // eslint-disable-next-line
    const [initDemoValueTest, setInitDemoValueTest] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedType, setSelectedType] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
    const [totalPages, setTotalPages] = useState(Math.ceil(filteredUserInfo.length / usersPerPage));
    const [inputPage, setInputPage] = useState('');
    // eslint-disable-next-line
    const [refresh, setRefresh] = useState(false);
    // eslint-disable-next-line
    const [useradminloggedin, setUseradminloggedin] = useState('');
    // eslint-disable-next-line
    const [currentUserAdminClicked, setCurrentUserAdminClicked] = useState('');
    // eslint-disable-next-line
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    // eslint-disable-next-line
    const [showAdminEditModal, setShowAdminEditModal] = useState(false);
    
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUserInfo.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    fetchUsers();
    fetchUserInfo();
    adjustUsersPerPage();
  }, []);

  const getToken = async () => {
    const auth = 'Basic d3R0X2FwaV91c2VyOlQwUlRQTnFrYmt3M2VhcWQ=';

    try {
        const response = await fetch('https://www.wise-tt.com/WTTWebRestAPI/ws/rest/login', {
            method: 'GET',
            headers: {
                'Authorization': auth,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data.token;
        } else {
            alert('Login failed');
        }
    } catch (error) {
        alert('An error occurred');
        alert(error);
    }
};

  const handleLogin = () => {
    if (users[email] && users[email] === password) {
      setUserLoggedIn(email);
    } else {
      alert('Invalid email or password');
    }
  };

  const fetchUsers = () => {
    fetch('https://wtimetable.com/QR/users.txt?timestamp=' + new Date().getTime())
      .then(response => response.text())
      .then(text => {
        const adminObj = text.trim().split('\n').reduce((acc, current) => {
          const [user, pass] = current.split("-,-,-,").map(item => item.trim());
          acc[user] = pass;
          return acc;
        }, {});
        setUsers(adminObj);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  };

  const fetchUserInfo = () => {
    fetch('https://wtimetable.com/QR/userinfo.txt?timestamp=' + new Date().getTime())
      .then(response => response.text())
      .then(text => {
        const lines = text.trim().split('\n');
        setUserinfo(lines);
        setFilteredUserInfo(lines);
      })
      .catch(error => {
        console.error('Error fetching user info:', error);
      });
  };

  const adjustUsersPerPage = () => {
    const screenHeight = window.innerHeight;
    const rowHeight = 65;
    setUsersPerPage(Math.floor((screenHeight - 300) / rowHeight));
  };

  const fetchUserQrCodes = async (email) => {
    try {
      const response = await fetch('https://wtimetable.com/QR/USERS/' + email + '/QrFile.txt?timestamp=' + new Date().getTime());
      const text = await response.text();
      return text;
    } catch (error) {
      console.error('Error fetching user QR codes:', error);
    }
  };

  const handleAddQrCode = () => {
    // change the number of generated qr cdoes in the file
    // spodnji koda dela
    const type = fetchUserQrCodes(userLoggedIn).then((data) => {
      const [, , type] = data.split(";").map(item => item.trim());
      return type;
    });
    createGuid(type);
    updateUserQrFile(userLoggedIn, fetchUserQrCodes, getToken);
  };

  const createGuid = async (type) => {
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // eslint-disable-next-line
      const r = Math.random() * 16 | 0;
      // eslint-disable-next-line
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    const init = 'person;en;SetUpYourQRCode;Demo;Demo;Demo;Demo;Demo';
    createGUIDFile(guid, init); // naredi file v GUIDS folderju
    fetchUserQrCodes(userLoggedIn).then((data) => { // preveri če je file že narejen in naredi qrLinks file v USERS/{user} folderju ali ga updejta
      const [, , , numberOfQrCodesGenerated] = data.split(";").map(item => item.trim());
      if (numberOfQrCodesGenerated === 0) {
        // createQrLinksFile(guid, userLoggedIn);
      } else {
        console.log(numberOfQrCodesGenerated);
        uploadQrLinksFile(guid, userLoggedIn);
      }
  });
  };

  const createGUIDFile = async (guid, init) => {
    //creates a guid folder with an empty init.txt file
    try {
      const textzabase64 = init
      const documentContent = btoa(textzabase64);
      const userToken = await getToken();
      const BearerToken = 'Bearer ' + userToken;
  
      const data = JSON.stringify({
        documentPath: 'GUIDS/' + guid + '/init.txt',
        documentContent: documentContent
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': BearerToken
        },
        data: data
      };
  
      const uploadResponse = await axios.request(config);
      console.log(JSON.stringify(uploadResponse.data));
    }
    catch (error) {
      console.error(error);
      alert('Failed to create GUID Folder');
    }
  
    try {
      const documentContent = btoa('demo;demo');
      const userToken = await getToken();
      const BearerToken = 'Bearer ' + userToken;
  
      const data = JSON.stringify({
        documentPath: 'GUIDS/' + guid + '/all.txt',
        documentContent: documentContent
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': BearerToken
        },
        data: data
      };
  
      const uploadResponse = await axios.request(config);
      console.log(JSON.stringify(uploadResponse.data));
    }
    catch (error) {
      console.error(error);
      alert('Failed to create GUID Folder');
    }
  
    try {
      const documentContent = btoa('demo;demo');
      const userToken = await getToken();
      const BearerToken = 'Bearer ' + userToken;
  
      const data = JSON.stringify({
        documentPath: 'GUIDS/' + guid + '/image.txt',
        documentContent: documentContent
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': BearerToken
        },
        data: data
      };
  
      const uploadResponse = await axios.request(config);
      console.log(JSON.stringify(uploadResponse.data));
    }
    catch (error) {
      console.error(error);
      alert('Failed to create GUID Folder');
    }
  
    try {
      // Call the function with the appropriate parameters
      fetchImageAndSend(guid, 'https://wtimetable.com/QR/languages/profile_picture.png');
      const documentContent = btoa('demo;demo');
      const userToken = await getToken();
      const BearerToken = 'Bearer ' + userToken;
  
      const data = JSON.stringify({
        documentPath: 'GUIDS/' + guid + '/video.txt',
        documentContent: documentContent
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': BearerToken
        },
        data: data
      };
  
      const uploadResponse = await axios.request(config);
      console.log(JSON.stringify(uploadResponse.data));
    }
    catch (error) {
      console.error(error);
      alert('Failed to create GUID Folder video');
    }
  };

  const updateUserQrFile = async (email, fetchUserQrCodes, getToken) => {
    try {
      const data = await fetchUserQrCodes(email);
      const [fetchedEmail, numberOfQrCodes, type, numberOfQrCodesGenerated] = data.split(";").map(item => item.trim());
      const updatedGeneratedCodes = parseInt(numberOfQrCodesGenerated, 10) + 1;
      const newUser = `${fetchedEmail};${numberOfQrCodes};${type};${updatedGeneratedCodes}\n`;
  
      const updatedText = newUser;
      const documentContent = btoa(updatedText);
      const userToken = await getToken();
      const BearerToken = 'Bearer ' + userToken;
  
      const requestData = JSON.stringify({
        documentPath: 'USERS/' + email + '/QrFile.txt',
        documentContent: documentContent
      });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': BearerToken
        },
        data: requestData
      };
  
      const uploadResponse = await axios.request(config);
      console.log(JSON.stringify(uploadResponse.data));
    } catch (error) {
      console.error(error);
      alert('Failed to update User QR Folder');
    }
  };

  const uploadQrLinksFile = async (guid, userloggedin) => {
    //uploads the guid to the qrlinks file
    const newGuid = `${guid}\n`;
    const user = userloggedin;
  
      const response = await fetch(`https://wtimetable.com/QR/USERS/${user}/qrlinks.txt?timestamp=` + new Date().getTime());
      let text = await response.text();
      text = text.replace('init', '');
      const updatedText = text + newGuid;
      const documentContent = btoa(updatedText);
      const userToken = await getToken();
  
      const BearerToken = 'Bearer ' + userToken;
      const data = JSON.stringify({
        documentPath: `USERS/${userloggedin}/QrLinks.txt`,
        documentContent: documentContent
      });
    try {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': BearerToken
        },
        data: data
      };
  
      const uploadResponse = await axios.request(config);
      console.log(JSON.stringify(uploadResponse.data));
    } catch (error) {
      console.error(error);
      alert('Failed to upload GUID');
    }
  };

  const fetchImageAndSend = async (guid, imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result.split(',')[1];
        const userToken = await getToken();
        const BearerToken = 'Bearer ' + userToken;

        const data = JSON.stringify({
          documentPath: 'GUIDS/' + guid + '/profile_picture.png',
          documentContent: base64data
        });

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://www.wise-tt.com/WTTWebRestAPI/ws/rest/uploadDocument',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': BearerToken
          },
          data: data
        };

        try {
          const uploadResponse = await axios.request(config);
          console.log(JSON.stringify(uploadResponse.data));
        } catch (error) {
          console.error(error);
          alert('Failed to upload image');
        }
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error(error);
      alert('Failed to fetch image');
    }
  };    

  const fetchInit = async (qrGuid) => {
    try {
      const response = await fetch('https://wtimetable.com/QR/GUIDS/' + qrGuid + '/init.txt?timestamp=' + new Date().getTime());
      const text = await response.text();
      setInitDemoValueTest(text);
    } catch (error) {
      console.error('Error fetching init:', error);
    }
  };

  const fetchQrLinks = (user) => {
    fetch(`https://wtimetable.com/QR/USERS/${user}/qrlinks.txt?timestamp=` + new Date().getTime())
      .then(response => response.text())
      .then(text => {
        const lines = text.trim().split('\n');
        setQrLinks(lines);
      })
      .catch(error => {
        console.error('Error fetching QR links:', error);
      });
  };

  
  async function handleQRBtnPress(guid) {
    setGuid(guid);
    setShowEditQRModal(true);
    fetchInit(guid);
};

const handleLongPress = (guid) => {
    setGuid(guid);
    setShowQRCodeLong(true);
    setShowEditQRModal(false);
  };

const downloadQRCode = (guid) => {
    const canvas = document.querySelector('canvas');
    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `${guid}.png`;
    link.click();
    }

const handleOpenQrCode = (guid) => {
    window.open(`https://wtimetable.com/QR/Application/?guid=${guid}`, '_blank');
    }
const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
const handlePageInputChange = (text) => {
      const page = parseInt(text, 10);
      if (!isNaN(page)) {
        setInputPage(page);
      } else {
        setInputPage('');
      }
    };  
    const handlePageJump = () => {
      handlePageChange(inputPage);
    };  
    const handlePreviousPage = () => {
      if (currentPage > 1) {
        handlePageChange(currentPage - 1);
      }
    };  
    const handleNextPage = () => {
      if (currentPage < totalPages) {
        handlePageChange(currentPage + 1);
      }
    };

    const handleEdit = (email) => {
      console.log(`Edit clicked for ${email}`);
      handleEditAdminBtnPress(email);
      //emailhere is the email of the user that was clicked
      setUseradminloggedin(email);
      setCurrentUserAdminClicked(email);
    };

    const handleAddUser = () => {
      setShowAddUserModal(true);
    };  
    async function handleEditAdminBtnPress(user) {
        setShowAdminEditModal(true);
    };

return (
    <div>
        {userLoggedIn === '' ? (
            <div>
                    <div className="headerView">
                            <a className='logo' href="https://kenjara.eu/" target="_blank" rel="noopener noreferrer">
                                <img src="https://wtimetable.com/QR/languages/logo.png" className="logoimg" alt="Logo" />
                            </a>
                            <h1 className="headerText">Wise Highlights</h1>
                    </div>
                <div style={styles.loginContainer}>
                    <h2>Login</h2>
                    <input
                        type="text"
                        style={styles.input}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        style={styles.input}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button style={styles.button} onClick={handleLogin}>
                        Login
                    </button>
                    {/* <p>
                        Don't have an account?{' '}
                        <span onClick={handleRegister} style={styles.registertext}>Register now</span>
                    </p> */}
                </div>
            </div>
        ) : userLoggedIn === 'admin' ? (
            <AdminView
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                currentUsers={currentUsers}
                filteredUserInfo={filteredUserInfo}
                setFilteredUserInfo={setFilteredUserInfo}
                currentPage={currentPage}
                usersPerPage={usersPerPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                inputPage={inputPage}
                handlePageChange={handlePageChange}
                handlePageInputChange={handlePageInputChange}
                handlePageJump={handlePageJump}
                handlePreviousPage={handlePreviousPage}
                handleNextPage={handleNextPage}
                handleEdit={handleEdit}
                handleLogout={() => setUserLoggedIn('')}
                handleAddUser={handleAddUser}
                refresh={refresh}
                getToken={getToken}
                userinfo={userinfo}
                fetchUserQrCodes={fetchUserQrCodes}
                addQrCode={handleAddQrCode}
                fetchQrLinks={fetchQrLinks}
                qrLinks={qrLinks}
                handleQRBtnPress={handleQRBtnPress}
                handleLongPress={handleLongPress}
            />
        ) : (
            <UserView 
                userloggedin={userLoggedIn}
                handleLogout={() => setUserLoggedIn('')}
                userinfo={userinfo}
                fetchUserQrCodes={fetchUserQrCodes}
                addQrCode={handleAddQrCode}
                fetchQrLinks={fetchQrLinks}
                qrLinks={qrLinks}
                handleQRBtnPress={handleQRBtnPress}
                handleLongPress={handleLongPress}
            />
        )}
        <QRCodeModal showQRCodeLong={showQRCodeLong} setShowQRCodeLong={setShowQRCodeLong} qrGuid={guid} downloadQRCode={downloadQRCode} handleOpenQrCode={handleOpenQrCode}/>
        <EditQRCodeModal showEditQRModal={showEditQRModal} setShowEditQRModal={setShowEditQRModal} initDemoValueTest={initDemoValueTest} userloggedin={userLoggedIn} qrGuid={guid}/>	
    </div>
);
}

function UserView({
    admin,
    userloggedin,
    handleLogout,
    userinfo,
    fetchUserQrCodes,
    addQrCode,
    fetchQrLinks,
    qrLinks,
    handleQRBtnPress,
    handleLongPress
  }) {
    const [numberOfQRCodes, setNumberOfQRCodes] = useState(0);
    const [numberOfQRCodesGenerated, setNumberOfQRCodesGenerated] = useState(0);
    const [userDetails, setUserDetails] = useState(null);
    const [qrData, setQrData] = useState([]);
  
    useEffect(() => {
      fetchUserQrCodes(userloggedin).then((data) => {
        const numberOfQRCodes = data ? parseInt(data.split(";")[1], 10) : 0;
        const numberOfQRCodesGenerated = data ? parseInt(data.split(";")[3], 10) : 0;
        setNumberOfQRCodes(numberOfQRCodes);
        setNumberOfQRCodesGenerated(numberOfQRCodesGenerated);
      });
      fetchQrLinks(userloggedin);
    }, [userloggedin, fetchUserQrCodes, addQrCode, fetchQrLinks]);
  
    useEffect(() => {
      if (userinfo) {
        const user = userinfo.find((info) => {
          const [, , email] = info.split(";").map(item => item.trim());
          return email === userloggedin;
        });
        setUserDetails(user);
      }
    }, [userinfo, userloggedin]);
  
    useEffect(() => {
      const fetchData = async () => {
        const dataPromises = qrLinks.map(link =>
          fetch(`https://wtimetable.com/QR/GUIDS/${link}/init.txt`)
            .then(response => response.text())
            .then(data => {
              const parts = data.split(';');
              return `${parts[2] || 'N/A'} ${parts[3] || 'N/A'}`;
            })
            .catch(() => 'Error fetching data')
        );
        const results = await Promise.all(dataPromises);
        setQrData(results);
      } 
      fetchData();
    }, [qrLinks]);

    return (
      <div style={{ flex: 1, backgroundColor: 'white', width: '100%' }}>
        <div className="headerView">
                <a className='logo' href="https://kenjara.eu/" target="_blank" rel="noopener noreferrer">
                  <img src="https://wtimetable.com/QR/languages/logo.png" className="logoimg" alt="Logo" />
                </a>
                <h1 className="headerText">Wise Highlights</h1>
  
          {admin ? (
            <button style={styles.userlogoutbutton} onClick={handleLogout}>
              ADMIN DELETE USER
            </button>
          ) : (
            <button style={styles.userlogoutbutton} onClick={handleLogout}>
              LOGOUT
            </button>
          )}
        </div>
        <div style={styles.userheader}>
          <div style={styles.yourcodesheader}>
            <h2 style={styles.userWelcomeText}>Welcome {userloggedin}</h2>
          </div>
          {userDetails && (
            <div style={styles.userInfoContainer}>
              <p style={styles.userInfoText}>Name: {userDetails.split(";")[0]}</p>
              <p style={styles.userInfoText}>Surname: {userDetails.split(";")[1]}</p>
              <p style={styles.userInfoText}>Number of QR Codes: {numberOfQRCodes}</p>
              <p style={styles.userInfoText}>Number of QR Codes Generated: {numberOfQRCodesGenerated}</p>
              <p style={styles.userInfoText}>Start Date: {userDetails.split(";")[5]}</p>
              <p style={styles.userInfoText}>Valid Until: {userDetails.split(";")[6]}</p>
              <p style={styles.userInfoText}>Note: {userDetails.split(";")[7]}</p>
            </div>
          )}
        </div>
  
        <div style={styles.yourcodesheader}>
          <h2 style={styles.userWelcomeText}>Your QR Codes (long press to open)</h2>
        </div>
  
        <div style={styles.qrButtonsContainer}>
          {Array.from({ length: numberOfQRCodesGenerated }).map((_, index) => (
            <button
              key={index}
              style={styles.qrButton}
              onContextMenu={(e) => {
                e.preventDefault();
                handleLongPress(qrLinks[index]);
              }}
              onClick={() => handleQRBtnPress(qrLinks[index])}
            >
               <QRCodeCanvas value={`https://wtimetable.com/QR/Application/?guid=${qrLinks[index]}`} />
              <p style={styles.qrButtonText}>{qrData[index]}</p>
            </button>
          ))}
  
          {!admin && numberOfQRCodesGenerated < numberOfQRCodes && (
            <button style={styles.qrAddButton} onClick={addQrCode}>
              <text style={styles.plus}>+</text>
            </button>
          )}
  
          {admin && numberOfQRCodesGenerated === 0 && (
            <p>User has not generated any codes yet.</p>
          )}
  
          {(numberOfQRCodesGenerated % 2 === 0 && numberOfQRCodesGenerated !== 0) && <div style={styles.plain} />}
          {(numberOfQRCodes % 2 !== 0 && numberOfQRCodesGenerated !== 0) && <div style={styles.plain} />}
        </div>
      </div>
    );
  }

const styles = {

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    headerView: {
        display: 'flex',
        alignItems: 'center',
    },
    logo: {
        width: 50,
        height: 50,
    },
    adminHeaderText: {
        marginLeft: 10,
    },
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        padding: 20,
        border: '1px solid #ccc',
        borderRadius: 5,
        margin: '0 auto',
        marginTop: window.innerHeight / 5,

    },
    input: {
        padding: 10,
        marginBottom: 10,
        borderRadius: 5,
        border: '1px solid #ccc',
    },
    button: {
        padding: 10,
        borderRadius: 5,
        border: 'none',
        backgroundColor: '#1C2541',
        color: '#fff',
        cursor: 'pointer',
    },
      userlogoutbutton: {
        textAlign: 'center',
        padding: 10,
        borderRadius: 5,
        position: 'absolute',
        right: 10,
        top: 10,
        border: 'none',
      },
      userheader: {
        width: '100%',
        backgroundColor: '#1C2541',
        justifyContent: 'center',
        top: 0,
      },
      yourcodesheader: {
        paddingTop: 1,
        width: '100%',
        backgroundColor: '#1C2541',
        justifyContent: 'center',
        top: 0,
        height: 70,
      },
      userWelcomeText: {
        color: 'white',
        textAlign: 'center',
        fontSize: 20,
      },
      userInfoContainer: {
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 5,
        marginLeft: 5,
        marginRight: 5,

      },
      userInfoText: {
        color: '#1C2541',
        fontSize: 16,
        marginBottom: 5,
        //a black bottom border
        borderBottom: '1px solid #1C2541',
      },
      qrButtonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        padding: '10px',
        justifyContent: 'center',
      },
      qrButton: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '10px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '48%',
        maxWidth: '200px',
      },
      qrButtonText: {
        marginTop: '10px',
        fontSize: '14px',
      },
      qrAddButton: {
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '10px',
        textAlign: 'center',
        width: '48%',
        maxWidth: '200px',
        cursor: 'pointer',
        borderColor: '#1C2541',
        borderWidth: '5px',
        backgroundColor: 'white',
        color: 'white',
      },
      plain: {
        flexBasis: '100%',
        height: '1px',
        backgroundColor: '#f0f0f0',
        margin: '20px 0',
      },
      plus: {
        fontSize: '120px',
        color: '#1C2541',
      },
};

export default App;